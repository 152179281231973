import React from 'react';
import {
  Divider,
  Row,
  Col,
  Card,
  Timeline,
} from 'antd';
import { domHtml } from '../../../utils/stripTags';
import SEO from '../../Seo';
import AboutTile from '../../AbouTile';

const pageText = {
  paraOne: `Xin chào! Mình là Mầu. Cựu sinh viên K57 Bách Khoa Hà Nội, đang làm việc tại Tokyo.
    Hiện tại mình đang là Senior Application Engineer của Dominos Pizza Japan - A Tech Company That Happens To Make Pizza 🍕`,
  paraTwo: `Khi nhập học Bách Khoa, mình đăng ký hệ tiếng Anh nhưng vì không đủ chỉ tiêu nên được offer qua hệ tiếng Nhật.
    Bằng một cái tặc lưỡi và sau 5 năm chơi bời vất vả thì hôm nay mình đang ngồi trong một căn phòng nhỏ đầy nắng ở Tokyo 🗼`,
  paraThree: `Blog sẽ là nơi chia sẻ những điều tâm tắc với bản thân trong quá trình học tập, làm việc.
    Ghé qua quán trà đá để tán gẫu cùng dăm ba câu chuyện phiếm vỉa hè.
    Ghé hàng cafe bạc sỉu nghiêm túc hơn để bàn luận về tech hay đơn giản là về những kiến thức mới.
    Chắc chắn rằng hãy ghé quán bia hơi nếu bạn muốn vi vu và hoà mình vào các hoạt động outdoors.
    Nếu có hứng thú thì đừng ngại mà drop me a line trong phần contact hoặc messenger nhé!`,
};
const { Meta } = Card;

const AboutMe = () => {
  const description = 'Blog của Mầu - System Engineer tại Tokyo. Hành trình của một kỹ sư công nghệ thông tin với Bạc sỉu ☕ dành cho những ý tưởng nghiêm túc, vài ly Trà đá 🍵 bên dăm ba mẩu chuyện vỉa hè và những cốc bia hơi 🍺 với những người bạn mới. ';
  return (
    <>
      <div>
        <SEO
          title="About"
          description={description}
          path=""
          keywords={['Mau', 'Mau Ha Quang', 'Ha Quang', 'System Engineer', 'AWS', 'Azure', 'Cloud computing', 'Solucation Architect', 'Sport industry']}
        />
        <h1 className="titleSeparate">Chủ nhà</h1>
        <p>
          {pageText.paraOne}
        </p>
        <p dangerouslySetInnerHTML={domHtml(pageText.paraTwo)} />
        <p dangerouslySetInnerHTML={domHtml(pageText.paraThree)} />
      </div>
      <div>
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="baby.png"
              alt="baby image"
              textH4="Sinh ra và lớn lên"
              textH3="Vĩnh Phúc ➡️ Hà Nội 🇻🇳"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="football.png"
              alt="football image"
              textH4="Mê thể thao"
              textH3="Sport + Me = ❤️"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="trekking.png"
              alt="trekking image"
              textH4="hoạt động ngoài trời"
              textH3="listen to nature calls 😂"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="bicycle.png"
              alt="bicycle image"
              textH4="đạp xe"
              textH3="cùng 'công chúa' CAAD12 🚵‍♀️"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="cloud.png"
              alt="cloud image"
              textH4="và lên mây"
              textH3="cloud computing enthusiast 🔥"
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={8}>
            <AboutTile
              img="tokyo-tower.png"
              alt="tokyo tower image"
              textH4="Đang dừng chân"
              textH3="Tokyo, JP 🇯🇵"
            />
          </Col>
        </Row>
        <Divider />
        <h1 className="titleSeparate">Work</h1>
        <Timeline pending="06/2021 - hiện tại Dominos Pizza Japan - Senior Application Engineer" reverse="true">
          <Timeline.Item>05/2016 - 10/2016 FRAMGIA VIETNAM - Engineer Trainee</Timeline.Item>
          <Timeline.Item>11/2017 - 05/2021 HOT-FACTORY.,LTD - System Engineer</Timeline.Item>
        </Timeline>
        <Divider />
        <h1 className="titleSeparate">Education</h1>
        <Timeline reverse="true">
          <Timeline.Item>09/2006 - 05/2009 HANOI - AMSTERDAM</Timeline.Item>
          <Timeline.Item>08/2009 - 05/2012 CHUYÊN LÝ TỔNG HỢP - K24B</Timeline.Item>
          <Timeline.Item>09/2012 - 07/2017 ĐẠI HỌC BÁCH KHOA HÀ NỘI - HEDSPI K57</Timeline.Item>
        </Timeline>
        <Divider />
        <h1 className="titleSeparate">Certificates</h1>
        <Timeline reverse="true">
          <Timeline.Item>12/2015 Japanese-Language Proficiency Test N3</Timeline.Item>
          <Timeline.Item>10/2016 FE (Fundamentals of Engineering Examination)</Timeline.Item>
          <Timeline.Item>08/2019 TOEIC Reading & Listening 940</Timeline.Item>
        </Timeline>
        <Row gutter={[20, 20]}>
          <Card
            style={{ width: 300 }}
            cover={(
              <img
                alt="cka"
                src="./cka.png"
              />
            )}
          >
            <Meta
              title="Certified Kubernetes Administrator"
              description="03/2022 - 03/2025"
            />
          </Card>
          <Card
            style={{ width: 300 }}
            cover={(
              <img
                alt="awssapro"
                src="./awssapro.png"
              />
            )}
          >
            <Meta
              title="Solutions Architect – Professional"
              description="12/2020 - 12/2023"
            />
          </Card>
          <Card
            style={{ width: 300 }}
            cover={(
              <img
                alt="awwsa"
                src="./awssa.png"
              />
            )}
          >
            <Meta
              title="Solutions Architect – Associate"
              description="04/2019 - 12/2023"
            />
          </Card>
          <Card
            style={{ width: 300 }}
            cover={(
              <img
                alt="awsda"
                src="./awsda.png"
              />
            )}
          >
            <Meta
              title="Developer - Associate"
              description="08/2019 - 08/2022"
            />
          </Card>
          <Card
            style={{ width: 300 }}
            cover={(
              <img
                alt="awssysops"
                src="./awssysops.png"
              />
            )}
          >
            <Meta
              title="Sysops Administrator - Associate"
              description="08/2020 - 08/2023"
            />
          </Card>
        </Row>
      </div>
    </>
  );
};
export default AboutMe;
